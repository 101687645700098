<template>
  <div>
    <v-dialog
      v-model='isAddNewCheck'
      persistent
      max-width='500'
    >
      <v-card>
        <v-card-title>
          {{ $t('add_checklist') }}
        </v-card-title>
        <v-form
          ref='formAddCheck'
          @submit.prevent='createCheck'
        >
          <v-card-text>
            <v-autocomplete
              v-model='checkgroup_id'
              :rules='[required]'
              :items='checkgroups'
              item-text='checkgroup_name'
              :label="$t('examination_category')"
              item-value='checkgroup_id'
              :no-data-text="$t('no_information')"
              outlined
              dense
            ></v-autocomplete>
            <v-select
              v-model.trim='checking_type_id'
              :items='checkingtypeList'
              :item-text='$i18n.locale'
              item-value='value'
              class='mt-3'
              :label="$t('type')"
              outlined
              dense
            ></v-select>
            <label for=''>{{ $t('examination_set') }}</label>
            <div class='d-flex'>
              <v-radio-group
                v-model.trim='checking_set_id'
                class='mt-0'
                row
              >
                <v-radio
                  :label="$t('general')"
                  :disabled='checking_type_id != 1'
                  value='0'
                ></v-radio>
                <v-radio
                  :label="$t('single')"
                  :disabled='checking_type_id != 2'
                  value='1'
                ></v-radio>
                <v-radio
                  :label="$t('series')"
                  :disabled='checking_type_id != 2'
                  value='2'
                ></v-radio>
              </v-radio-group>
              <v-checkbox hide-details class='mt-0' :label="$t('sendCheckLab')" true-value='1'
                          v-if='cblab==1'
                          v-model='checking_cblab'
                          false-value='0'></v-checkbox>
            </div>
            <v-text-field
              v-model='checking_code'
              autofocus
              class='mt-3'
              :label="$t('examination_code')"
              :rules='[required]'
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model='checking_name'
              :rules='[required]'
              class='mt-3'
              :label="$t('examination_list')"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model='checking_fee'
              type='number'
              class='mt-3'
              :rules='[required]'
              :label="$t('doctor_fee')"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model='checking_cost'
              class='mt-3'
              :rules='[required]'
              type='number'
              outlined
              :label="$t('commissions')"
              :disabled='checking_set_id == 2'
              dense
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color='primary'
              :loading='loading'
              :disabled='loading'
              type='submit'
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color='secondary'
              outlined
              @click="$emit('update:is-add-new-check',false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import checkGroup from '@/api/examination/checkGroup'
import check_type from '@/@fake-db/data/check_type.json'
import { required } from '@/@core/utils/validation'
import document from '@/api/systemSetting/document'
import check from '@/api/examination/check'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewCheck',
    event: 'update:is-add-new-check',
  },
  props: {
    isAddNewCheck: Boolean,
    cblab: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const checking_cblab = ref('0')
    const checkgroup_id = ref('')
    const checking_type_id = ref(1)
    const checking_set_id = ref('0')
    const checking_code = ref('')
    const checking_name = ref('')
    const checking_fee = ref('0')
    const checking_cost = ref('0')
    const checkingtypeList = ref(check_type.data)
    const checkgroups = ref([])
    const loading = ref(false)

    const formAddCheck = ref(null)

    const { checkAdd } = check
    const createCheck = () => {
      const isFormValid = formAddCheck.value.validate()
      if (!isFormValid) return
      loading.value = true
      checkAdd({
        checkgroup_id: checkgroup_id.value,
        checking_type_id: checking_type_id.value,
        checking_set_id: checking_set_id.value,
        checking_code: checking_code.value,
        checking_name: checking_name.value,
        checking_fee: checking_fee.value,
        checking_cost: checking_cost.value,
        checking_cblab: checking_cblab.value,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-add-new-check', false)
        loading.value = false
        emit('onAdd')
      })
    }

    const { checkGroupList } = checkGroup
    const { documentSetting } = document

    const getDocs = () => {
      documentSetting().then(res => {
        checking_code.value = res.check
      })
    }

    const getCheckCourseList = () => {
      checkGroupList({
        searchtext: '',
        checkgroup_status_id: 1,
      }).then(res => {
        checkgroups.value = res
        checkgroup_id.value = res[res.length - 1].checkgroup_id
      })
    }

    watch([checking_type_id], value => {
      checking_set_id.value = value == 1 ? '0' : '1'
    })

    const clearData = () => {
      getDocs()
      getCheckCourseList()
      checking_name.value = ''
      checking_type_id.value = 1
      checking_set_id.value = '0'
      checking_fee.value = '0'
      checking_cost.value = '0'
      checking_cblab.value = '0'
    }

    watch(() => props.isAddNewCheck, newValue => {
      if (newValue) {
        clearData()
      }
    })

    return {
      loading,
      formAddCheck,
      checkgroup_id,
      checking_type_id,
      checking_set_id,
      checking_code,
      checking_name,
      checking_fee,
      checking_cost,
      checkingtypeList,
      checkgroups,
      checking_cblab,
      createCheck,
      required,
    }
  },
}
</script>

<style lang='scss' scoped>

</style>
