import basicStatus from '@/@fake-db/data/basicStatus.json'
import CheckingType from '@/@fake-db/data/CheckingType.json'
import check from '@/api/examination/check'
import checkGroup from '@/api/examination/checkGroup'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import { onMounted, ref, watch } from '@vue/composition-api'

export default function useCheckList() {
  const dataTableList = ref([])
  const loading = ref(false)
  const columns = ref([{
    text: '#', align: 'start', sortable: false, value: 'checking_id', width: 30,
  }, {
    text: i18n.t('code'), value: 'checking_code', width: 100,
  },

    {
      text: i18n.t('category'), value: 'checkgroup_name', width: 130,
    },

    {
      text: i18n.t('examination_list'), value: 'checking_name', width: 180,
    }, {
      text: i18n.t('detail'), value: 'checklist', width: 200,
    }, {
      text: i18n.t('type'), align: 'center', width: 100, value: 'checking_type_id',
    }, {
      text: i18n.t('examination_set'), value: 'checking_set_id', align: 'center', width: 130,
    }, {
      text: i18n.t('doctor_fee'), value: 'checking_fee', align: 'end', width: 120,
    }, {
      text: i18n.t('examination_fee'), value: 'checking_cost', align: 'end', width: 120,
    }, {
      text: i18n.t('status'), align: 'center', width: 100, value: 'checking_status_id',
    }, {
      text: i18n.t('option'), value: 'actions', sortable: false, align: 'center', width: 150,
    }])
  const totalDataTableList = ref(0)
  const segmentId = ref(0)
  const totalPage = ref(0)
  const options = ref({})
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)

  const searchtext = ref('')
  const checkgroup_id = ref('')
  const checkgroupList = ref([])
  const checking_status_id = ref(1)
  const checking_type_id = ref('')
  const checkingtypeList = ref(CheckingType.data)
  const statusList = ref(basicStatus.data)
  const cblabStatus = ref(null)
  const isAddNewCheck = ref(false)

  const isEditCheck = ref(false)
  const dataEdit = ref({})

  const isUpdateStatus = ref(false)
  const checking_id = ref('')
  const checking_status_id_update = ref('')

  const isImportFile = ref(false)

  const { checkList } = check
  const { checkGroupList } = checkGroup
  checkGroupList({
    searchtext: '', checkgroup_status_id: 1,
  }).then(res => {
    checkgroupList.value = res
    checkgroupList.value.unshift({
      checkgroup_id: '', checkgroup_name: i18n.t('all'),
    })
  })

  onMounted(() => {
    fetchDataTable()
  })

  const fetchDataTable = event => {
    loading.value = true
    checkList({
      searchtext: searchtext.value,
      checkgroup_id: checkgroup_id.value,
      checking_status_id: checking_status_id.value,
      checking_type_id: checking_type_id.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }).then(res => {
      const { data, count, segment, count_of_page, cblab } = res
      cblabStatus.value = cblab
      dataTableList.value = data
      totalPage.value = count_of_page
      totalDataTableList.value = count
      segmentId.value = segment
      if (event == 'add') {
        options.value.page = count_of_page
      } else if (event == 'delete') {
        if (options.value.page > count_of_page) {
          options.value.page = count_of_page
          fetchDataTable()
        }
      }
      loading.value = false
    })
  }
  watch([searchtext, checkgroup_id, checking_status_id, checking_type_id, options], (newvalue, oldvalue) => {
    if (newvalue[0] !== oldvalue[0] || newvalue[1] !== oldvalue[1] || newvalue[2] !== oldvalue[2] || newvalue[3] !== oldvalue[3]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  return {
    isImportFile,
    isUpdateStatus,
    checking_id,
    checking_status_id_update,
    isEditCheck,
    dataEdit,
    isAddNewCheck,
    dataTableList,
    checkingtypeList,
    loading,
    columns,
    totalDataTableList,
    segmentId,
    options,
    footer,
    searchtext,
    checkgroup_id,
    totalPage,
    checkgroupList,
    checking_status_id,
    checking_type_id,
    statusList,
    cblabStatus,
    fetchDataTable,
  }
}
