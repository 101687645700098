<template>
  <div>
    <v-dialog
      v-model="isImportFile"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('import_file_examination_list') }} (.CSV)
          <v-spacer></v-spacer>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <a :href="check_form">
                <v-btn
                  color="primary"
                  class="ma-2"
                  v-bind="attrs"
                  x-small
                  fab
                  v-on="on"
                >
                  <v-icon dark>{{ icons.mdiCloudDownload }}</v-icon>
                </v-btn>
              </a>
            </template>
            <span>{{ $t("download_form") }}</span>
          </v-tooltip>
        </v-card-title>
        <v-form
          ref="formImportFile"
          @submit.prevent="sendFile"
        >
          <v-card-text>
            <v-autocomplete
              v-model="checkgroup_id"
              outlined
              dense
              :label="$t('examination_category')"
              :rules="[required]"
              :items="checkgroup_data"
              :no-data-text="$t('no_information')"
              item-text="checkgroup_name"
              item-value="checkgroup_id"
            ></v-autocomplete>
            <v-file-input
              v-model="files"
              :label="$t('choose_a_file')"
              show-size
              dense
              outlined
              classes="mt-3"
              accept=".csv"
              @change="checkFile"
            ></v-file-input>
            <span class="ml-3">Log</span> <span
              v-if="checkLoading"
              class="primary--text"
            >{{ $t('checkDataLoading') }}</span>
            <v-sheet
              outlined
              rounded="lg"
              height="100"
            >
              <v-virtual-scroll
                :items="dataCheckFile.data"
                :item-height="20"
                height="100"
              >
                <template v-slot:default="{ item,index }">
                  <span
                    class="text-sm ml-2"
                    :class="item.status_id==0?'error--text':'success--text'"
                  > {{ index+1 }}) {{ item.status_id==0? item.status_message :`ข้อมูลถูกต้อง (${item.checking_code} ${item.checking_name})` }} <v-icon
                    small
                    :color="item.status_id==0?'error':'success'"
                  >{{ item.status_id==0? icons.mdiClose:icons.mdiCheck }}</v-icon> </span>
                </template>
              </v-virtual-scroll>
            </v-sheet>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading || !files||dataCheckFile.checkfalse>=1 || checkLoading"
              type="submit"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-import-file',false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiCloudDownload } from '@mdi/js'
import checkGroup from '@/api/examination/checkGroup'
import check_form from '@/assets/download_files/check_form.csv'
import { required } from '@/@core/utils/validation'
import check from '@/api/examination/check'
import store from '@/store'
import { i18n } from '@/plugins/i18n'
import checkImportFile from '@/api/checkImportFile'

export default {
  model: {
    prop: 'isImportFile',
    event: 'update:is-import-file',
  },
  props: {
    isImportFile: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const files = ref(null)
    const checkgroup_data = ref([])
    const checkgroup_id = ref('')
    const formImportFile = ref(null)
    const loading = ref(false)
    const checkLoading = ref(false)
    const dataCheckFile = ref({})

    const { checkGroupList } = checkGroup

    const getCheckGroupList = () => {
      checkGroupList({
        searchtext: '',
        checkgroup_status_id: 1,
      }).then(res => {
        checkgroup_data.value = res
        checkgroup_id.value = res[res.length - 1].checkgroup_id
      })
    }

    const sendFile = () => {
      const isFormValid = formImportFile.value.validate()
      if (!isFormValid) return
      loading.value = true
      const formData = new FormData()
      formData.append('checkgroup_id', checkgroup_id.value)
      formData.append('file', files.value)
      formData.append('lang', i18n.locale)
      check.checkImportFile(formData).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
      })
      emit('update:is-import-file', false)
      emit('onSend')
      files.value = null
      loading.value = false
    }

    watch(() => props.isImportFile, value => {
      if (value) {
        getCheckGroupList()
        files.value = null
      }
    })
    const checkFile = () => {
      checkLoading.value = true
      const formData = new FormData()
      formData.append('checkgroup_id', checkgroup_id.value)
      formData.append('file', files.value)
      formData.append('lang', i18n.locale)
      checkImportFile.checkImportFileChecking(formData).then(res => {
        dataCheckFile.value = res
        checkLoading.value = false
      })
    }

    return {
      loading,
      formImportFile,
      sendFile,
      files,
      check_form,
      required,
      checkgroup_data,
      checkLoading,
      checkFile,
      dataCheckFile,
      checkgroup_id,
      icons: {
        mdiCloudDownload,
      },
      getCheckGroupList,

    }
  },
}
</script>
