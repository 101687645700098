<template>
  <div>
    <v-card>
      <v-card-title class='px-2'>
        {{ $t('examination_list') }}
        <v-spacer></v-spacer>
        <v-btn
          color='primary'
          class='me-2 d-none d-md-block'
          @click='isAddNewCheck = true'
        >
          <v-icon class='me-1'>
            {{ icons.mdiPlus }}
          </v-icon>
          <span class='d-none d-sm-block'> {{ $t('add_checklist') }}</span>
        </v-btn>
        <v-btn
          color='primary'
          fab
          outlined
          icon
          class='me-2 d-block d-md-none'
          @click='isAddNewCheck = true'
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
        <v-btn
          color='secondary'
          class='d-none d-md-block'
          outlined
          @click='isImportFile =true'
        >
          <v-icon
            size='17'
            class='me-1'
          >
            {{ icons.mdiFileImportOutline }}
          </v-icon>
          <span class='d-none d-sm-block'>{{ $t('import_files_csv') }}</span>
        </v-btn>
        <v-btn
          color='secondary'
          outlined
          fab
          class='d-block d-md-none'
          icon
          @click='isImportFile =true'
        >
          <v-icon>
            {{ icons.mdiFileImportOutline }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class='px-2'>
        <v-col
          cols='12'
          class='py-0'
          md='6'
          lg='3'
        >
          <v-text-field
            v-model='searchtext'
            :label="$t('search')"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col
          cols='12'
          md='6'
          class='py-0'
          lg='3'
        >
          <v-select
            v-model='checking_type_id'
            :items='checkingtypeList'
            :label="$t('type')"
            dense
            outlined
            :item-text='$i18n.locale'
            item-value='value'
          ></v-select>
        </v-col>
        <v-col
          cols='12'
          md='6'
          class='py-0'
          lg='3'
        >
          <v-autocomplete
            v-model.trim='checkgroup_id'
            :items='checkgroupList'
            :label="$t('examination_category')"
            :no-data-text="$t('no_information')"
            dense
            outlined
            item-text='checkgroup_name'
            item-value='checkgroup_id'
          ></v-autocomplete>
        </v-col>
        <v-col
          cols='12'
          md='6'
          class='py-0'
          lg='3'
        >
          <v-select
            v-model='checking_status_id'
            :items='statusList'
            :label="$t('status')"
            :item-text='$i18n.locale'
            outlined
            dense
            item-value='value'
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers='columns'
        :items='dataTableList'
        :options.sync='options'
        :server-items-length='totalDataTableList'
        :footer-props='footer'
        :loading='loading'
        hide-default-footer
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.checking_id`]='{ index }'>
          {{ segmentId + index + 1 }}
        </template>
        <template v-slot:[`item.checking_code`]='{ item }'>
          <router-link
            class='font-weight-medium text-decoration-none'
            :to="{ name: 'Check-detail', params: { id: item.checking_id } }"
          >
            {{ item.checking_code }}
          </router-link>
        </template>
        <template v-slot:[`item.checklist`]='{ item }'>
          <ol start='1'>
            <li
              v-for='(check, index) in item.checklist'
              :key='index'
            >
              {{ check.checklist_name }}
            </li>
          </ol>
        </template>
        <template v-slot:[`item.checking_type_id`]='{ item }'>
          <CheckType :type='item.checking_type_id' />
        </template>
        <template v-slot:[`item.checking_set_id`]='{ item }'>
          <CheckSetType :type='item.checking_set_id' />
        </template>
        <template v-slot:[`item.doctor_fee`]='{ item }'>
          {{ item.doctor_fee  | formatPrice }}
        </template>
        <template v-slot:[`item.checking_cost`]='{ item }'>
          {{ item.checking_cost  | formatPrice }}
        </template>
        <template v-slot:[`item.checking_status_id`]='{ item }'>
          <StatusBlock :status='item.checking_status_id' />
        </template>
        <template v-slot:[`item.actions`]='{ item }'>
          <router-link
            class='font-weight-medium text-decoration-none'
            :to="{ name: 'Check-detail', params: { id: item.checking_id } }"
          >
            <v-tooltip
              color='#212121'
              top
            >
              <template v-slot:activator='{ on, attrs }'>
                <v-btn
                  icon
                  color='primary'
                  v-bind='attrs'
                  v-on='on'
                >
                  <v-icon>{{ icons.mdiInformationOutline }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('detail') }}</span>
            </v-tooltip>
          </router-link>
          <v-tooltip
            color='#212121'
            top
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                icon
                color='primary'
                v-bind='attrs'
                @click='dataEdit = item;isEditCheck = true'
                v-on='on'
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>
          <v-tooltip
            color='#212121'
            top
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                icon
                color='primary'
                v-bind='attrs'
                @click='checking_id = item.checking_id;checking_status_id_update = item.checking_status_id;isUpdateStatus = true'
                v-on='on'
              >
                <v-icon>{{ item.checking_status_id == 1 ? icons.mdiDeleteOutline : icons.mdiCheck
                  }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ item.checking_status_id == 1 ? $t('suspend') : $t('normal') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data='options.page'
        :page-count='totalPage'
        :segment-id='+segmentId '
        :count-list='dataTableList.length'
        :total='+totalDataTableList'
        @pageChanged='(page)=>{options.page = page ; fetchDataTable()}'
        @itemChanged='(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}'
      />
    </v-card>
    <AddNewCheck
      v-model='isAddNewCheck'
      :cblab='cblabStatus'
      @onAdd="fetchDataTable('add')"
    />
    <EditCheck
      v-model='isEditCheck'
      :data-edit='dataEdit'
      :cblab='cblabStatus'
      @onUpdate="fetchDataTable('update')"
    />
    <UpdateStatusCheck
      v-model='isUpdateStatus'
      :check-id='checking_id'
      :check-status-id='checking_status_id_update'
      @onUpdate="fetchDataTable('delete')"
    />
    <ImportFile v-model='isImportFile' />
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiCheck,
  mdiInformationOutline,
  mdiFileImportOutline,
} from '@mdi/js'
import useCheckList from './useCheckList'
import CheckType from '@/components/basicComponents/CheckType.vue'
import CheckSetType from '@/components/basicComponents/CheckSetType.vue'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import AddNewCheck from './AddNewCheck.vue'
import EditCheck from './EditCheck.vue'
import UpdateStatusCheck from './UpdateStatusCheck.vue'
import ImportFile from './ImportFile.vue'
import Pagination from '@/components/basicComponents/Pagination.vue'
import { formatPrice } from '@/plugins/filters'

export default {
  components: {
    CheckType,
    ImportFile,
    CheckSetType,
    StatusBlock,
    Pagination,
    AddNewCheck,
    EditCheck,
    UpdateStatusCheck,
  },
  filters: {
    formatPrice,
  },
  setup() {
    return {
      ...useCheckList(),
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiCheck,
        mdiInformationOutline,
        mdiFileImportOutline,
      },
    }
  },
}
</script>

<style lang='scss' scoped>

</style>
