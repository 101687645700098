<template>
  <div>
    <v-dialog
      v-model='isEditCheck'
      persistent
      max-width='500'
    >
      <v-card>
        <v-card-title>
          {{ $t('edit_check_list') }}
          <CheckSetType
            class='ml-2'
            :type='dataEditLocal.checking_set_id'
          />
        </v-card-title>
        <v-form
          ref='formEditCheck'
          @submit.prevent='updateCheck'
        >
          <v-card-text>
            <v-autocomplete
              v-model='dataEditLocal.checkgroup_id'
              :rules='[required]'
              :items='checkgroups'
              item-text='checkgroup_name'
              :label="$t('examination_category')"
              item-value='checkgroup_id'
              :no-data-text="$t('no_information')"
              outlined
              dense
            ></v-autocomplete>

            <v-text-field
              v-model='dataEditLocal.checking_code'
              autofocus
              class='mt-3'
              :label="$t('examination_code')"
              :rules='[required]'
              outlined
              dense
            ></v-text-field>
            <v-checkbox hide-details class='mt-0' :label="$t('sendCheckLab')" true-value='1'
                        v-if='cblab==1'
                        v-model='dataEditLocal.checking_cblab'
                        false-value='0'></v-checkbox>
            <v-text-field
              v-model='dataEditLocal.checking_name'
              :rules='[required]'
              class='mt-3'
              :label="$t('examination_list')"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model='dataEditLocal.checking_fee'
              type='number'
              class='mt-3'
              :rules='[required]'
              :label="$t('doctor_fee')"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model='dataEditLocal.checking_cost'
              class='mt-3'
              :rules='[required]'
              type='number'
              outlined
              :label="$t('commissions')"
              :disabled='dataEditLocal.checking_set_id == 2'
              dense
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color='primary'
              :loading='loading'
              :disabled='loading'
              type='submit'
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color='secondary'
              outlined
              @click="$emit('update:is-edit-check',false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import checkGroup from '@/api/examination/checkGroup'
import check_type from '@/@fake-db/data/check_type.json'
import { required } from '@/@core/utils/validation'
import check from '@/api/examination/check'
import store from '@/store'
import CheckSetType from '@/components/basicComponents/CheckSetType.vue'

export default {
  components: { CheckSetType },
  model: {
    prop: 'isEditCheck',
    event: 'update:is-edit-check',
  },
  props: {
    isEditCheck: Boolean,
    cblab: {
      type: Number,
      default: 0,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const dataEditLocal = ref({})
    const checkingtypeList = ref(check_type.data)
    const checkgroups = ref([])
    const loading = ref(false)

    const formEditCheck = ref(null)

    const { checkUpdate } = check
    const updateCheck = () => {
      const isFormValid = formEditCheck.value.validate()
      if (!isFormValid) return
      loading.value = true
      delete dataEditLocal.value.checklist
      delete dataEditLocal.value.checkgroup_name
      delete dataEditLocal.value.checking_status_id
      checkUpdate(dataEditLocal.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-edit-check', false)
        loading.value = false
        emit('onUpdate')
      })
    }

    const { checkGroupList } = checkGroup

    const getCheckCourseList = () => {
      checkGroupList({
        searchtext: '',
        checkgroup_status_id: 1,
      }).then(res => {
        checkgroups.value = res
      })
    }

    watch(() => props.dataEdit, newValue => {
      if (newValue) {
        getCheckCourseList()
        dataEditLocal.value = JSON.parse(JSON.stringify(newValue))
      }
    })

    return {
      dataEditLocal,
      checkgroups,
      loading,
      formEditCheck,
      checkingtypeList,
      updateCheck,
      required,
    }
  },
}
</script>

<style lang='scss' scoped>

</style>
